
const CheckoutFailure = () => {
    return (
        <div className="min-h-screen text-center flex flex-col items-center justify-center">
            
            <h1 className="text-red-500 font-bold text-2xl">failed</h1>
            <h1 className="font-bold text-6xl mb-2">well, this is awkward</h1>
            <p>The checkout session was not completed successfully</p>
            <p className=" w-1/2 opacity-20 mb-10">If you cancelled it on purpose, feel free to ignore this message, otherwise please submit a complaint using the form on your dashboard.</p>
            <a className="bg-red-500 hover:bg-red-700 p-2 px-5 rounded-lg" href="/dashboard">Go to Dashboard</a>
            
        </div>
    )
}


export default CheckoutFailure