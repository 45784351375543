import { useState, useLayoutEffect, useContext, useEffect} from "react";
import useFirebaseUser from "../firebase/useFirebaseUser";
import BackgroundBlobs from "../components/BackgroundBlobs";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import Loading from "../components/Loading";
import FlashMessage from "../components/FlashMessage";
import { LuLoader2 } from "react-icons/lu";

const Contact = () => {

    return (
        <div className="min-h-screen">
            
    
            <div className="min-h-screen grid grid-cols-1 lg:grid-cols-2 ">
                <div className="bg-blue-500">
                    <img src="contact_image.png" alt="" />
                </div>
                <div style={{zIndex: 3}} className="pt-30 lg:pt-52 p-10 px-10">
                    <form action="https://public.herotofu.com/v1/61353440-234f-11ef-b435-b1ba21672864" method="POST" className="h-full w-full flex flex-col">
                        <h1 className="text-5xl font-bold mb-2">contact us</h1>
                        <p className="mb-2">use this form for general queries or to otherwise contact the Arnold team</p>
                        <p className="mb-5 opacity-50">someone from the team will get back to you within 48 hours</p>
                        <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-3">
                            <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">FULL NAME</h3>
                            <input required style={{outline: 'none'}} type="text" name="name" placeholder="John Doe" className="w-full bg-transparent" />
                        </div>
                        <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-3">
                            <h3 className="text-xs font-semibold text-black/50 dark:text-white/50">EMAIL</h3>
                            <input required style={{outline: 'none'}} type="email" placeholder="john.doe@gmail.com"  name="email" className="w-full bg-transparent" />
                        </div>
                        <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-5">
                            <h3 className="text-xs font-semibold text-black/50 dark:text-white/50">CONTENT</h3>
                            <textarea required style={{outline: 'none'}} rows={4} placeholder="Go ahead, we're listening..."  name="mainText" className="w-full bg-transparent" />
                        </div>

                        <button type="submit" className="p-3 text-white rounded-md mb-3 flex flex-col bg-blue-500 hover:bg-gray-800 flex justify-center items-center">
                            <p>submit</p>
                        </button>

                        
                    </form>
                </div>

            </div>

        </div>
    )

}

export default Contact;