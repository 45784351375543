import { useState, useLayoutEffect, useContext, useEffect} from "react";
import useFirebaseUser from "../firebase/useFirebaseUser";
import BackgroundBlobs from "../components/BackgroundBlobs";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import Loading from "../components/Loading";
import FlashMessage from "../components/FlashMessage";

const Signup = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [industry, setIndustry] = useState('')
    const [role, setRole] = useState('')
    const [howDidYouHear, setHowDidYouHear] = useState('')
    const [error, setError] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loading, setLoading] = useState(true)
    const [pageNum, setPageNum] = useState(0)


    const navigate = useNavigate();

    // const {login, logout, user} = useFirebaseUser();
    const {login, signup, user, checkVerification, resendVerificationEmail} = useContext(AuthContext)

    const handleSubmit = () => {
        try {
            
            if (password === confirmPassword) {
                if (name) {
                    signup(email, password, name, industry, role, howDidYouHear).then(() => { navigate('/verify-email') }).catch((err: any) => setError(err.message.split('Firebase: ')[1]));
                } else {
                    throw ({message: 'The Name field is required'})
                }
            } else {
                throw ({message: 'Password and Confirm Password fields do not match'})
            }
        } catch (err: any) {
            setError(err.message)

        }
    }


    useEffect(() => {
        if (user) {
            navigate('/dashboard')
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])

    const incrementPage = () => {
        if (pageNum < PAGES.length - 1) {
            setPageNum(pageNum + 1)
        }
    }

    const decrementPage = () => {
        if (pageNum > 0) {
            setPageNum(pageNum - 1)
        }
    }

    const PAGES = [
        <div className="h-full w-full flex flex-col">
            <h1 className="text-5xl font-bold mb-2">sign up</h1>
            <p className="mb-5">already have an account? <a className="text-blue-500 hover:text-blue-300" href="/login">log in here</a></p>
            <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-3">
                <div className="flex w-full justify-between">
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">EMAIL</h3>
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">REQUIRED</h3>
                </div>
                <input value={email} onChange={(e) => setEmail(e.target.value)} style={{outline: 'none'}} type="text" name="" placeholder="john.doe@email.com" id="" className="w-full bg-transparent" />
            </div>
            <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-3">
                <div className="flex w-full justify-between">
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">PASSWORD</h3>
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">REQUIRED</h3>
                </div>
                <input value={password} onChange={(e) => setPassword(e.target.value)} style={{outline: 'none'}} type="password"  name="" id="" className="w-full bg-transparent" />
            </div>
            <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-6">
                <div className="flex w-full justify-between">
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">CONFIRM PASSWORD</h3>
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">REQUIRED</h3>
                </div>
                <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} style={{outline: 'none'}} type="password"  name="" id="" className="w-full bg-transparent" />
            </div>

            <div className="flex justify-end">
                
                <button onClick={incrementPage} className="p-3 w-1/5 text-white rounded-md mb-3 flex flex-col bg-blue-500 hover:bg-gray-800 flex justify-center items-center">
                    <p>next</p>
                </button>
            </div>
    
            
        </div>,
        <div className="h-full w-full flex flex-col">
            <h1 className="text-5xl font-bold mb-2">about you</h1>
            <p className="mb-5">help us get to know you better</p>
            <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-3">
                <div className="flex w-full justify-between">
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">NAME</h3>
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">REQUIRED</h3>
                </div>
                <input value={name} onChange={(e) => setName(e.target.value)} style={{outline: 'none'}} type="text" name="" placeholder="John Doe" id="" className="w-full bg-transparent" />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mb-3">
                <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600">
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50">INDUSTRY</h3>
                    <input value={industry} onChange={(e) => setIndustry(e.target.value)} style={{outline: 'none'}} type="text" placeholder="Computer Science, Finance, etc." name="" id="" className="w-full bg-transparent" />
                </div>
                <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600">
                    <h3 className="text-xs font-semibold text-black/50 dark:text-white/50">ROLE</h3>
                    <input value={role} onChange={(e) => setRole(e.target.value)} style={{outline: 'none'}} type="text" placeholder="Student, Employee, New Grad, etc." name="" id="" className="w-full bg-transparent" />
                </div>

            </div>
            <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-6">
                <h3 className="text-xs font-semibold text-black/50 dark:text-white/50">HOW DID YOU HEAR?</h3>
                <input value={howDidYouHear} onChange={(e) => setHowDidYouHear(e.target.value)} style={{outline: 'none'}} placeholder="LinkedIn, Reddit, Ads, etc." type="text"  name="" id="" className="w-full bg-transparent" />
            </div>

            <div className="flex justify-between">
                <button onClick={decrementPage} className="p-3 w-1/5 text-white rounded-md mb-3 flex flex-col bg-gray-400 dark:bg-gray-800 hover:bg-gray-900 flex justify-center items-center">
                    <p>back</p>
                </button>
                <button onClick={handleSubmit} className="p-3 w-1/5 text-white rounded-md mb-3 flex flex-col bg-blue-500 hover:bg-gray-800 flex justify-center items-center">
                    <p>next</p>
                </button>
            </div>
            
        </div>,
        <div className="h-full w-full flex flex-col">
            <h1 className="text-5xl font-bold mb-2">verify your email</h1>
            <p className="mb-5">please verify your email address by clicking on the link sent to {email}. If you don't see it in your inbox, please check your spam/junk folders.</p>
            <p className="mb-5">Once verified, please come back here and click the 'Verified' button below.</p>
            

            <div className="flex justify-between">
                <button onClick={resendVerificationEmail} className="p-3 px-10 min-w-1/5 text-white rounded-md mb-3 flex flex-col bg-gray-400 dark:bg-gray-800 hover:bg-gray-900 flex justify-center items-center">
                    <p>resend verification email</p>
                </button>
                <button onClick={() => {
                    const verified = checkVerification()
                    if (verified) {
                        navigate('/dashboard')
                    }
                }} className="p-3 px-10 min-w-1/5 text-white rounded-md mb-3 flex flex-col bg-blue-500 hover:bg-gray-800 flex justify-center items-center">
                    <p>verified</p>
                </button>
            </div>
            
        </div>,
    
    ]


    return (
        <div className="min-h-screen">
            
            {loading ?
            <div className="min-h-screen flex justify-center items-center">
                <Loading />

            </div>
            :
            <div className="min-h-screen grid grid-cols-1 lg:grid-cols-2 ">
            <FlashMessage mode={'error'} message={error} />
                
                <div style={{zIndex: 3}} className="pt-52 px-10">

                    {PAGES[pageNum]}
                 
                </div>
                <div className="bg-blue-500">
                    <img src="signup_image.png" className="" alt="" />
                </div>

            </div>}

        </div>
    )

}

export default Signup;