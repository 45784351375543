import { LuLoader2 } from "react-icons/lu";

const Loading = () => {
    return (
        
        <LuLoader2 size={100} className="text-blue-500 animate-spin" />
       
    )
}

export default Loading