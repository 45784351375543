import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import { ThemeProvider } from 'next-themes';
import './index.css';

import useFirebaseUser from './firebase/useFirebaseUser';


import reportWebVitals from './reportWebVitals';

//pages
// import Home from './pages/Home'
import HomeTwo from './pages/HomeTwo';
import Login from './pages/Login';
import Register from './pages/Register'
import Contact from './pages/Contact'
import Dashboard from './pages/Dashboard';
import VerifyEmail from './pages/VerifyEmail';
import NotFound from './pages/NotFound';
import CheckoutSuccess from './pages/CheckoutSuccess';
import CheckoutFailure from './pages/CheckoutFailure';

//components
import Header from './components/Header'
import Footer from './components/Footer';
import ThankYou from './pages/ThankYou';
import Privacy from './pages/Privacy';
import Signup from './pages/Signup';
import { AuthContext } from './providers/AuthProvider';

const App = () => {

    // const {user} = useFirebaseUser();
    const {user} = useContext(AuthContext)



    return (
    <ThemeProvider attribute='class'>
    
      <head>
        <title>Arnold | AI Networking Assistant</title>
        
      </head>
      
      
        <Header />
        <BrowserRouter>
          <Routes>
            <Route index element={<HomeTwo/>} />
            {/* <Route path='/register' element={<Register/>} /> */}
            <Route path='/contact' element={<Contact/>} />
            <Route path='/thank-you' element={<ThankYou/>} />
            <Route path='/privacy-policy' element={<Privacy/>} />
            <Route path='/login' element={user ? <Navigate replace to={'/dashboard'} /> : <Login/>} />
            <Route path='/signup' element={user ? <Navigate replace to={'/dashboard'} /> : <Signup/>} />

            <Route path='/verify-email' element={<VerifyEmail />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/checkout/success' element={<CheckoutSuccess />} />
            <Route path='/checkout/failed' element={<CheckoutFailure />} />
            <Route path='*' element={<NotFound />} />

            

          </Routes>
        </BrowserRouter>
        <Footer />
      

    </ThemeProvider>
    )
}

export default App;