import { getApps, initializeApp } from "firebase/app"
import { GoogleAuthProvider, getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
 
const clientCredentials = {
    apiKey: "AIzaSyDxHti-GudgGgau5ret4zpKrGM1HeI8DAQ",
    authDomain: "arnold-c9e99.firebaseapp.com",
    projectId: "arnold-c9e99",
    storageBucket: "arnold-c9e99.appspot.com",
    messagingSenderId: "177737525617",
    appId: "1:177737525617:web:48dff8a7bd68fdab0c2ed0",
    measurementId: "G-XN4Y4PCE34"
}
 
let firebase_app
 
// Check if firebase app is already initialized to avoid creating new app on hot-reloads
if (!getApps().length) {
  firebase_app = initializeApp(clientCredentials)
} else {
  firebase_app = getApps()[0]
}
 
export const firebaseStorage = getStorage(firebase_app)
export const auth = getAuth(firebase_app)
export const firestore = getFirestore(firebase_app)
export const googleAuth = new GoogleAuthProvider()
 
export default firebase_app