const NotFound = () => {
    return (
        <div className="min-h-screen flex flex-col justify-center items-center text-center">
            <h1 style={{fontSize: '13rem'}} className="text-5xl font-bold mb-3">404</h1>
            <h4 className="text-2xl mb-6">Page Not Found</h4>
            <p className="mb-10 w-1/2"> Oops, it seems like you tried to navigate to a page that doesn't exist</p>
            <a href="/" className="bg-blue-500 hover:bg-blue-800 rounded-lg text-white font-bold px-10 p-2">Home</a>
        </div>
    )
}

export default NotFound;
