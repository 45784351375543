import { useState, useEffect } from 'react'


const TITLES: any = {
    error: 'Uh Oh',
    success: 'Yay!'
}

const FlashMessage = ({message, mode} : any) => {
    const [display, setDisplay] = useState(false)

    useEffect(() => {
        if (message !== '') {
            setDisplay(true)
            setTimeout(() => {
                setDisplay(false)
            }, 5000)
        }
    }, [message])
    return (
        <div style={{position: 'absolute', top: 100, left: '30%', width: '40%'}} className={`${mode === "error" ? 'bg-red-500' : mode === "success" ? 'bg-green-500' : 'bg-gray-700'} drop-shadow-lg rounded-lg p-3 text-white ${display ? '' : 'hidden'}`}>
            <h1 className='font-bold text-lg mb-0'>{TITLES[mode]}</h1>
            <p className='text-sm mt-0'>{message}</p>
        </div>
    )
}

export default FlashMessage;