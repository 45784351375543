import { useState, useEffect, createContext } from "react";
import {
    type User,
    type UserCredential,
    browserLocalPersistence,
    onAuthStateChanged,
    setPersistence,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification
} from "firebase/auth"
   
import { auth, firestore } from "../firebase/firebaseClient"
import {doc, getDoc, setDoc, updateDoc, increment} from 'firebase/firestore'
   

export const AuthContext = createContext<any>({})

export const AuthProvider = ({children}: any) => {
    const [user, setUser] = useState<User | null>()
    const [userDetails, setUserDetails] = useState<any>({tokens: 0, email: ''})

    console.log(user)

    setPersistence(auth, browserLocalPersistence)

    useEffect(() => {
        const unsubscribe =  onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user)
            } else {
                setUser(undefined)
            }
        })
        return () => unsubscribe()
    }, [])

    const getUserDetails = async () => {
        if (user) {
            const docRef = doc(firestore, "UserDetails", user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setUserDetails(docSnap.data())
            } else {
                return null
            }
        } else {
            return null
        }
    }

    useEffect(() => {
        getUserDetails()
    }, [user])

    return (
        <AuthContext.Provider
            value={{
                user,
                userDetails,
                logout: async () => {
                    await auth.signOut();
                },
                login: async (email: string, password: string) => {
                    await signInWithEmailAndPassword(auth, email, password).then((signedInUser: UserCredential) => {
                        setUser(signedInUser.user)
                    })
                },
                getUserDetails,
                signup: async (email: string, password: string, name: string, industry: string, role: string, howDidYouHear: string) => {
                    await createUserWithEmailAndPassword(auth, email, password).then(async (signedInUser: UserCredential) => {
                        setUser(signedInUser.user)
                        sendEmailVerification(signedInUser.user).then(async () => {
                            const newUserDetails = {
                                id: signedInUser.user.uid,
                                email: signedInUser.user.email,
                                profile: "",
                                lastTokenReset: new Date(),
                                tokenBalance: 10000,
                                maxTokens: 10000,
                                name,
                                industry,
                                role,
                                howDidYouHear,
                                premium: false
                              }
                    
                              const newConversations = {
                                id: signedInUser.user.uid,
                                numItems: 0,
                                items: {0: []}
                              }
                    
                    
                              await setDoc(doc(firestore, "UserDetails", signedInUser.user.uid), newUserDetails);
                              await setDoc(doc(firestore, 'Conversations', signedInUser.user.uid), newConversations)
                        })
                    })
                },
                checkVerification: async () => {
                    await auth.currentUser?.reload()
                    return auth.currentUser?.emailVerified
                },
                resendVerificationEmail: async () => {
                    if (user) {
                        await sendEmailVerification(user)
                    }
                },
               

            }}
        > 
        {children}
        </AuthContext.Provider>
    )
}