import {useState, useEffect, useContext} from 'react'
import { AuthContext } from '../providers/AuthProvider'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'
import { LuLoader2 } from "react-icons/lu";
import FlashMessage from '../components/FlashMessage';



const VerifyEmail = () => {
    const [loading, setLoading] = useState(true)
    const [actionLoading, setActionLoading] = useState(false)
    const [mode, setMode] = useState('error')
    const [message, setMessage] = useState('')
    
    const {user, checkVerification, resendVerificationEmail} = useContext(AuthContext)

    const navigate = useNavigate()

    useEffect(() => {
        if (!user) {
            navigate('/login')
        } else if (user && user.emailVerified) {
            navigate('/dashboard')
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])


    return (
        <div className="min-h-screen">
            
            {loading ?
            <div className="min-h-screen flex justify-center items-center">
                <Loading />

            </div>
            :
            <div className="min-h-screen grid grid-cols-1 lg:grid-cols-2 ">
       
                <FlashMessage mode={mode} message={message} />
                <div style={{zIndex: 3}} className="pt-52 px-10">

                <div className="h-full w-full flex flex-col">
                    <h1 className="text-5xl font-bold mb-2">verify your email</h1>
                    <p className="mb-5">please verify your email address by clicking on the link sent to {user?.email}. If you don't see it in your inbox, please check your spam/junk folders.</p>
                    <p className="mb-5">Once verified, please come back here and click the 'Verified' button below.</p>
                    

                    <div className="flex justify-between">
                        <button onClick={async () => {
                            setActionLoading(true)
                            
                            resendVerificationEmail()
                            .then(() => {
                                setMode('success')
                                setMessage('Verification email sent successfully')
                            })
                            .catch((err: any) => {
                                setMode('error')
                                setMessage(err.message)
                            })
                            .finally(() => {
                                setActionLoading(false)
                            })
                            
                        }} disabled={actionLoading} className="p-3 px-10 min-w-1/5 text-white rounded-md mb-3 flex flex-col bg-gray-400 dark:bg-gray-800 hover:bg-gray-900 flex justify-center items-center">
                            {actionLoading ? <LuLoader2 size={25} className="text-black/50 animate-spin" /> : <p>resend verification email</p>}
                        </button>
                        <button onClick={() => {
                            const verified = checkVerification()
                            if (verified) {
                                navigate('/dashboard')
                            }
                        }} className="p-3 px-10 min-w-1/5 text-white rounded-md mb-3 flex flex-col bg-blue-500 hover:bg-gray-800 flex justify-center items-center">
                            <p>verified</p>
                        </button>
                    </div>
                    
                </div>,
                 
                </div>
                <div className="bg-blue-500"></div>

            </div>}

        </div>
    )
}

export default VerifyEmail;