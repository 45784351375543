import { useState, useEffect, useContext } from "react";
import useFirebaseUser from "../firebase/useFirebaseUser";
import { AuthContext } from "../providers/AuthProvider";
import { useNavigate, redirect } from "react-router-dom";
import Loading from "../components/Loading";

const stripe = require('stripe')('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');

interface UserDetails {
    email: string,
    tokenBalance: number
}


const Dashboard = () => {
    const [loading, setLoading] = useState(true)
    const {user, userDetails, logout} = useContext(AuthContext)

    const navigate = useNavigate()

    useEffect(() => {
        if (!user) {
            navigate('/login')
        } else if (!user.emailVerified) {
            navigate('/verify-email')
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [user])

    const postToCheckout = async () => {
        try {
            const session = await stripe.checkout.sessions.create({
                line_items: [
                    {
                        // Provide the exact Price ID (for example, pr_1234) of the product you want to sell
                        price: 'price_1PMZ1BRr0vk7nSjKbxrgtVlr',
                        quantity: 1,
                    }
                ],
                mode: 'subscription',
                success_url: `${window.location.origin}/checkout/success`,
                cancel_url: `${window.location.origin}/checkout/failed`,
                // success_url: `http://network/checkout_success`,
                // cancel_url: `http://localhost:3001/checkout_error`,
                client_reference_id: userDetails.id
            })
            window.location.href = session.url
        } catch (err: any) {
            console.log(err)
        }
    }


    console.log(user)
    return (
        <div className="min-h-screen">
            {
                user && !loading ?
                <div className="p-10 lg:p-24 pt-36 min-h-screen">
                <div className="flex justify-between items-center mb-10">
                    <div className="flex flex-col">
                        <h1 className="font-bold text-5xl">🚀 dashboard</h1>
                        <p className="text-2xl">welcome back, {userDetails.email}!</p>
                    </div>
                    <div style={{width: 100, height: 100, borderRadius: 50}} className="hidden lg:flex bg-red-500">

                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 mb-36 text-white">
                    {!user.emailVerified && 
                    <div className="bg-red-500 rounded-lg flex flex-col justify-between p-3">
                        <div>
                            <h4 className="text-lg font-semibold">Verify Email</h4>
                            <p className="text-sm mb-2">Please verify your email so we know you’re a real person. Email verification is required to be able to use Arnold.</p>

                        </div>
                        <div className="flex w-full">
                            <button className="bg-black/50 p-3 py-2 rounded-lg mr-2">refresh</button>
                            <button className="bg-white text-black p-3 py-2 rounded-lg grow">resend verification email</button>
                        </div>
                    </div>
                    }
                    {userDetails.profile === '' && 
                    <div className="bg-red-500 rounded-lg flex flex-col justify-between p-3">
                        <div>
                            <h4 className="text-lg font-semibold">LinkedIn Profile Missing</h4>
                            <p className="text-sm mb-2">To maximise Arnold’s effectiveness and let him know who you are, navigate to your LinkedIn profile, and click the “This is me” button on the Arnold overlay.</p>
                        </div>
                        
                        {/* <button className="bg-white text-black p-3 py-2 rounded-lg">watch video tutorial</button> */}
                        
                    </div>}
                    <div className="bg-green-500 rounded-lg flex flex-col justify-between p-3">
                        <h4 className="text-lg font-semibold">Daily Usage</h4>
                        <p className="text-sm">Tokens Used: <strong>{userDetails.maxTokens - userDetails.tokenBalance}</strong></p>
                        <p className="text-sm">Daily Limit: <strong>{userDetails.maxTokens}</strong></p>
                        <p className="text-sm mb-2">Tokens Refresh at: <strong>{new Date(userDetails?.lastTokenReset?.seconds * 1000 + 86400000).toString()}</strong></p>
                        {!userDetails.premium && <button className="bg-white text-black p-3 py-2 rounded-lg">upgrade to pro for unlimited</button>}

                    </div>
                </div>
                {/* <p>{userDetails.email}</p>
                <p>{userDetails.tokenBalance}</p> */}
                <div className="mb-24">
                    <h1 className="text-4xl mb-3">Getting Started</h1>
                    <p className="mb-5">Hi there! I’m so glad you chose to sign up for Arnold to help take your networking game to the next level, but before we can do that, please follow the instructions below to ensure that everything is set up correctly.</p>
                    <div>
                        <h3 className="font-bold text-lg">Download the Chrome Extension</h3>
                        <p className="mb-2">The Arnold application currently only supports Google Chrome, and can be downloaded and added to the Google Chrome browser by installing through the Chrome Web Store.</p>
                        <p className="mb-2">To do so now, navigate to the Arnold Chrome Webstore page, and click the “Add to Chrome” button, and follow the instructions on the screen to add the extension to your Chrome browser</p>
                        <p className="mb-5">Once complete, click on the Extensions icon (looks like a puzzle piece) on the top right of the window to bring up your downloaded extensions, find Arnold in the list, and click the pin icon beside it’s name to pin the extension for easy access</p>
                        <a target="__blank" className="bg-blue-500 hover:bg-blue-700 p-2 rounded-lg px-5 font-bold text-white" href="https://chromewebstore.google.com/detail/arnold/oeggnnpjdldnlcblnadgbbojicofmdfh">Download Arnold Chrome Extension</a>
                    </div>
                </div>
                <div className="mb-24">
                    <h1 className="text-4xl mb-3">Current Plan: <strong>{userDetails.premium ? 'Pro' : 'Free'}</strong></h1>
                    {userDetails.premium ?
                    <p className="mb-5">You’re currently on the "Pro" plan, which means that your usage of Arnold is completely unrestricted and Arnold is able to automatically extract information from most LinkedIn pages.</p>
                    :
                    <p className="mb-5">You’re currently on the “Free” plan, which means that your Arnold usage is limited to {userDetails.maxTokens} tokens a day and Arnold is able to automatically extract information from Profile and Job pages only.</p>
                    }
                    {userDetails.premium ? 
                        <p className="opacity-50">If you wish to cancel your subscription, please fill out the complaint form below and a team member will get back to you within 24 hours.</p>
                    :
                    <div className="flex">
                        <a className="px-24 mr-3 py-2 rounded-lg bg-gray-400 dark:bg-gray-800" href="">compare plans</a>
                        
                        {/* <a href={`http://localhost:3000/?user=${userDetails.id}`} className="px-24 py-2 text-white bg-gradient-to-r from-blue-500 to-green-500 font-semibold rounded-lg">upgrade to pro</a> */}
                        <button onClick={() => postToCheckout()} className="px-24 py-2 text-white bg-gradient-to-r from-blue-500 to-green-500 font-semibold rounded-lg">upgrade to pro</button>

                            
                        
                    </div>
                    }
                </div>
                <div>
                    <h1 className="text-4xl mb-3">Feedback</h1>
                    <p className="mb-5">Unhappy with Arnold, or ran into a bug? Please let us know using the form below, and we’ll be sure to get back to you within 48 hours. Sorry for the inconvenience!</p>
                    <form method="POST" action="https://public.herotofu.com/v1/61353440-234f-11ef-b435-b1ba21672864">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                            <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-3">
                                <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">NAME</h3>
                                <input style={{outline: 'none'}} type="text" name="name" placeholder="John Doe" className="w-full bg-transparent" />
                            </div>
                            <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-3">
                                <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">EMAIL</h3>
                                <input style={{outline: 'none'}} type="email" name="email" placeholder="john.doe@email.com" className="w-full bg-transparent" />
                            </div>
                        </div>
                        <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-3">
                            <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">COMPLAINT/BUG</h3>
                            <textarea rows={5} style={{outline: 'none'}} name="mainText" placeholder="Go ahead, we're listening..."  className="w-full bg-transparent" />
                        </div>
                        <div className="w-full flex justify-end">
                            <button className="p-3 rounded-lg bg-blue-500 hover:bg-blue-700 text-white">submit</button>
                        </div>
                    </form>

                </div>

              
                
            </div>
            :
            <div className="min-h-screen flex items-center justify-center">
                <Loading />
            </div>
            }

        </div>
        
        
    
    )
  
}


export default Dashboard;