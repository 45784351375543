import { useState, useLayoutEffect, useContext, useEffect} from "react";
import useFirebaseUser from "../firebase/useFirebaseUser";
import BackgroundBlobs from "../components/BackgroundBlobs";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import Loading from "../components/Loading";
import FlashMessage from "../components/FlashMessage";
import { LuLoader2 } from "react-icons/lu";

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [actionLoading, setActionLoading] = useState(false)


    const navigate = useNavigate();

    // const {login, logout, user} = useFirebaseUser();
    const {login, logout, user} = useContext(AuthContext)

    const handleSubmit = () => {
        setActionLoading(true)
        login(email, password).then(() => {
            navigate('/dashboard')
        }).catch((err: any) => {
            setError(err.message.split('Firebase: ')[1])
        }).finally(() => {
            setActionLoading(false)
        })
        
    }

    useEffect(() => {
        if (user) {
            navigate('/dashboard')
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])


    return (
        <div className="min-h-screen">
            
            {loading ?
            <div className="min-h-screen flex justify-center items-center">
                <Loading />

            </div>
            :
            <div className="min-h-screen grid grid-cols-1 lg:grid-cols-2 ">
                <FlashMessage mode={'error'} message={error} />
                <div className="bg-blue-500">
                    <img src="signin_image.png" alt="" />
                </div>
                <div style={{zIndex: 3}} className="pt-30 lg:pt-52 p-10 px-10">
                    <div className="h-full w-full flex flex-col">
                        <h1 className="text-5xl font-bold mb-2">sign in</h1>
                        <p className="mb-5">don't have an account yet? <a className="text-blue-500 hover:text-blue-300" href="/signup">sign up here</a></p>
                        <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-3">
                            <h3 className="text-xs font-semibold text-black/50 dark:text-white/50 ">EMAIL</h3>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} style={{outline: 'none'}} type="text" name="" placeholder="john.doe@email.com" id="" className="w-full bg-transparent" />
                        </div>
                        <div className="p-2 rounded-md flex flex-col bg-gray-300 dark:bg-gray-600 mb-5">
                            <h3 className="text-xs font-semibold text-black/50 dark:text-white/50">PASSWORD</h3>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} style={{outline: 'none'}} type="password"  name="" id="" className="w-full bg-transparent" />
                        </div>

                        <button disabled={actionLoading} onClick={handleSubmit} className="p-3 text-white rounded-md mb-3 flex flex-col bg-blue-500 hover:bg-gray-800 flex justify-center items-center">
                            {actionLoading ?  <LuLoader2 size={25} className="text-black/50 animate-spin" /> : <p>login</p>}
                        </button>
                        {/* <a className="text-blue-500 text-sm hover:text-blue-300" href="">forgot your password?</a> */}
                        
                    </div>
                </div>

            </div>}

        </div>
    )

}

export default Login;