import { useState } from "react";
import { FaNewspaper } from "react-icons/fa6";
import { FaMessage } from "react-icons/fa6";
import { BsFillFilePersonFill } from "react-icons/bs";
import { MdBusinessCenter } from "react-icons/md";

const DemoSwitcher = () => {
    const [selectedDemo, setSelectedDemo] = useState(0)
    const [videoLoaded, setVideoLoaded] = useState(false)

    const DEMOS = [
        {
            title: 'Feed',
            desc: 'Enhance feed engagement with personalized commenting suggestions',
            icon: <FaNewspaper size={17}/>,
            demoSource: 'feed_demo.mp4',
            color: 'text-blue-500'
        },
        {
            title: 'Messages',
            desc: 'Craft goal-oriented messages with tailored suggestions based on conversation history',
            icon: <FaMessage size={17}/>,
            demoSource: 'messaging_demo.mp4',
            color: 'text-rose-500'
        },
        {
            title: 'Profile',
            desc: 'Summarize profiles and optimize your own with instant, insightful feedback',
            icon: <BsFillFilePersonFill size={20}/>,
            demoSource: 'profile_demo.mp4',
            color: 'text-green-500'
        },
        {
            title: 'Jobs',
            desc: 'Navigate job postings effortlessly with cover letter generation and expert advice',
            icon: <MdBusinessCenter size={22} />,
            demoSource: 'jobs_demo.mp4',
            color: 'text-orange-500'
        },
    ];

    return (
        <div className="w-full  p-3 rounded-lg bg-white/50 dark:bg-black/50 backdrop-blur-lg dark:text-white">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full mb-3">
                {DEMOS.map((demo, index) => {
                    return (
                        <button onClick={() => {
                            setSelectedDemo(index)
                            setVideoLoaded(false)
                        }} className={`flex flex-col p-3 rounded-lg hover:bg-slate-100 hover:dark:bg-slate-900 text-left ${index === selectedDemo ? 'bg-slate-100 dark:bg-slate-900' : ''}`}>
                            <div className={`flex items-center mb-2 ${index === selectedDemo ? demo.color : ''}`}>
                                {demo.icon}
                                
                                <h4 className="font-semibold text-lg ml-2">{demo.title}</h4>
                            </div>
                            <p className="text-sm">{demo.desc}</p>
                        </button>
                    )
                })}
                {/* <button className="flex flex-col p-3 rounded-lg bg-slate-100 text-left">
                    <h4 className="font-semibold">Feature Title</h4>
                    <p className="text-sm">Lorem ipsum dolor sit amet consectetur adipisicing.</p>
                </button>
                <button className="flex flex-col p-3 rounded-lg bg-slate-100 text-left">
                    <h4 className="font-semibold">Feature Title</h4>
                    <p className="text-sm">Lorem ipsum dolor sit amet consectetur adipisicing.</p>
                </button>
                <button className="flex flex-col p-3 rounded-lg bg-slate-100 text-left">
                    <h4 className="font-semibold">Feature Title</h4>
                    <p className="text-sm">Lorem ipsum dolor sit amet consectetur adipisicing.</p>
                </button>
                <button className="flex flex-col p-3 rounded-lg bg-slate-100 text-left">
                    <h4 className="font-semibold">Feature Title</h4>
                    <p className="text-sm">Lorem ipsum dolor sit amet consectetur adipisicing.</p>
                </button> */}

            </div>
            <div style={{width: '100%'}}>
                {/* <p>Demo from {DEMOS[selectedDemo].demoSource}</p> */}
                <video onLoadedData={() => setVideoLoaded(true)} key={DEMOS[selectedDemo].demoSource} className={`rounded-lg ${videoLoaded ? '' : 'hidden'}`} controls={false} autoPlay loop muted>
                    <source src={DEMOS[selectedDemo].demoSource} type="video/mp4"></source>
                </video>
                {!videoLoaded && <img src="placeholder_for_demo.png" alt="" />}

            </div>
        </div>
    )
}

export default DemoSwitcher;