import { GoCheckCircle } from "react-icons/go";

function ThankYou() {



    return (
        <div className='p-6 md:p-10 pt-3 min-h-screen'>
            
            <div className='p-0 md:p-6 md:p-10 md:px-10 md:px-36  mt-24 flex flex-col items-center justify-center text-center'>
                <GoCheckCircle size={300} className="text-blue-500 font-bold mb-5"/>
                <h1 className="text-blue-500 text-6xl mb-3 font-black">Form Submitted!</h1>
                <p className='w-1/2 mb-3 text-sm'>Thank you! If you're seeing this page, it means the form was submitted successfully.</p>
                <p className='w-1/2 text-gray-400 text-sm mb-5'>Note: If your submission warrants a response, expect one from a team member within 48 hours</p>
                <a className="bg-blue-500 hover:bg-blue-700 rounded-lg px-5 py-3 font-bold text-white" href="/">Go Home</a>
            
            </div>

           
        </div>
    )
}

export default ThankYou;