
const CheckoutSuccess = () => {
    return (
        <div className="min-h-screen text-center flex flex-col items-center justify-center">
            
            <h1 className="text-green-500 font-bold text-2xl">success!</h1>
            <h1 className="font-bold text-6xl mb-2">thank you</h1>
            <p>... for trusting Arnold to help you take your networking game to the next level. </p>
            <p className=" w-1/2 opacity-20 mb-10">In the Arnold extension, log out and log back in for the changes to take effect. Ensure your dashboard now displays your current plan as "Pro". In case of any inconsistences, please fill out and submit the complaint form on your Dashboard.</p>
            <a className="bg-green-500 hover:bg-green-700 p-2 px-5 rounded-lg" href="/dashboard">Go to Dashboard</a>
            
        </div>
    )
}

export default CheckoutSuccess