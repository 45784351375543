import BackgroundBlobs from "../components/BackgroundBlobs"
import DemoSwitcher from "../components/DemoSwitcher"
import { FaCircleCheck } from "react-icons/fa6";
import { useState } from "react";
// import { ThemeProvider } from "next-themes";

const TESTIMONIALS = [
    {
        name: 'John Doe',
        occupation: 'Student, University of Waterloo',
        review: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt autem ut, dicta repellat eos vitae!'
    },
    {
        name: 'Jane Doe',
        occupation: 'Student, University of Waterloo',
        review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus dolor error ipsa, alias rerum qui mollitia illum magni. Esse, eveniet!'
    },
    {
        name: 'Bassam Ahmed',
        occupation: 'Software Engineering Intern, Sony Interactive Entertainment',
        review: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam, laborum saepe numquam, necessitatibus temporibus quia culpa velit nam labore dicta commodi sequi dolorum repudiandae fugit, eum molestias provident iusto deserunt?'
    },
    {
        name: 'Random Person',
        occupation: 'Consultant, McKinsey',
        review: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Soluta, pariatur.'
    },
    {
        name: 'John Doe',
        occupation: 'Student, University of Waterloo',
        review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus dolor error ipsa, alias rerum qui mollitia illum magni. Esse, eveniet!'
    },
    {
        name: 'Jane Doe',
        occupation: 'Student, University of Waterloo',
        review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus dolor error ipsa, alias rerum qui mollitia illum magni. Esse, eveniet!'
    },
]

const FREE_TIER_BENEFITS = [
    "Arnold automatically extracts information from LinkedIn Profile and Job pages only",
    "Usage limit is capped at 10,000 tokens a day (both input and output)",
]

const PRO_TIER_BENEFITS = [
    "Arnold automatically extracts information from almost all LinkedIn pages: Profile, Job, Messages, and the Feed",
    "No daily usage limit. Use Arnold without worrying about tokens"
]

const HomeTwo = () => {

    const [videoLoaded, setVideoLoaded] = useState(false)

    return (
        // <ThemeProvider attribute="class">
        <div>
            <div className="hidden lg:block">
                <BackgroundBlobs top={400} left={-500} />
                <BackgroundBlobs top={1800} left={'30%'} />
                {/* <BackgroundBlobs top={2000} left={-600} /> */}
            </div>

            <div style={{position: 'absolute',top: 0, left: '80%', height: 700, width: 300, zIndex: 4}} className="bg-gradient-to-l hidden lg:flex from-white dark:from-black"></div>
            <div style={{zIndex: 2, position: 'relative'}}>
                <div id="home" className="h-screen w-full grid grid-cols-1 lg:grid-cols-2">
                    <div className="flex flex-col p-10 px-5 lg:px-24 justify-center">
                        <h1 className="bg-gradient-to-br from-blue-500 to-blue-900 font-bold text-5xl mb-2 inline-block text-transparent bg-clip-text pb-3">level up your networking game</h1>
                        <p className="mb-5">...and stop missing out on life-changing opportunities</p>
                        <p className="mb-5">Use Arnold as a built-in, context-aware assistant to do literally everything on LinkedIn - from proofreading your email to a recruiter to generating a comment for a post in your feed</p>
                        <div className="flex font-bold text-white text-sm mb-5">
                            <a className="p-3 rounded-lg bg-blue-500 hover:bg-blue-700 px-5 mr-3" href="/signup">try it out</a>
                            <a className="p-3 rounded-lg bg-slate-500 hover:bg-slate-700 px-5" href="/#product">watch demo</a>
                        </div>
                        <a href="https://www.producthunt.com/posts/arnold-2?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-arnold&#0045;2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=463700&theme=neutral" alt="Arnold - AI&#0032;Assistant&#0032;for&#0032;LinkedIn | Product Hunt" style={{width: 250, height: 54}} width="250" height="54" /></a>
                    </div>
                    <div className="hidden lg:flex justify-center items-center">
                        <video style={{marginRight: -300}} className={`border-4 md:block border-blue-500 rounded-lg scale-125`} controls={false} autoPlay loop muted>
                            <source src={'main_demo_v0.5.1.mp4'} type="video/mp4"></source>
                        </video>
                        {/* <img className={`border-4 border-blue-500 rounded-lg scale-125 ${videoLoaded ? 'hidden' : ''}`} style={{marginRight: -100}} src="placeholder_for_demo.png" alt="" /> */}
                    </div>
                    <div className="flex lg:hidden justify-center items-center p-3">
                        <video  className={`w-full border-4 md:block border-blue-500 rounded-lg `} controls={false} autoPlay loop muted>
                            <source src={'main_demo_v0.5.1.mp4'} type="video/mp4"></source>
                        </video>
                        {/* <img className={`border-4 border-blue-500 rounded-lg scale-125 ${videoLoaded ? 'hidden' : ''}`} style={{marginRight: -100}} src="placeholder_for_demo.png" alt="" /> */}
                    </div>
                </div>
                <div id="vision" className="m-5 mx-3 lg:mx-14 p-5 md:p-14 rounded-md backdrop-blur-md bg-slate-700/50 bg-slate-500 text-white text-right flex flex-col">
                    <h5 className="font-bold">THE VISION</h5>
                    <p className="text-2xl lg:text-4xl font-extralight">  to create a seamless, intelligent assistant for LinkedIn users, designed to provide accurate answers and follow instructions with minimal user input - making LinkedIn interactions, networking, and job applications effortless and empowering users with a smart companion that is always aware of the context and behaves accordingly.</p>
                </div>
                <div id="product" className="m-5 p-3 lg:p-14">
                    <h5 className="text-white font-bold">THE PRODUCT</h5>
                    <p className="text-4xl text-white font-extralight mb-5">Arnold does it all</p>
                    <DemoSwitcher />
                </div>
                {/* <div id="people" className="m-5 p-5 md:p-14 text-right flex flex-col items-end">
                    <h5 className="font-bold">THE PEOPLE</h5>
                    <p className="text-4xl w-2/3 font-extralight mb-7">At the end of the day, Arnold is here to help <strong className="font-bold">you</strong> like it helped these users</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                        {
                            TESTIMONIALS.map(item => {
                                return (
                                    <div className="rounded-lg bg-slate-100/50 dark:bg-black/50 backdrop-blur-lg p-5 flex flex-col">
                                        <h2 className="mb-0 text-lg font-bold">{item.name}</h2>
                                        <p className="mt-0 mb-5 text-black/50 dark:text-white/50">{item.occupation}</p>
                                        <p>{item.review}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> */}
                <div id="pricing" className="m-5 md:mx-14 md:p-14 flex flex-col items-end items-center">
                    <h3 className="font-bold">PRICING</h3>
                    <p className="text-4xl text-center w-2/3 font-extralight mb-7">Pick an Arnold plan that suits <strong>your</strong> needs</p>
                    <div className="w-full lg:w-9/12 grid grid-cols-1 md:grid-cols-2 gap-5">
                        <div className="rounded-3xl p-3 bg-gradient-to-br dark:from-gray-700 dark:to-gray-900 p-3 from-gray-200 to-gray-400">
                            <div className="bg-white/30 dark:bg-black/30 rounded-2xl p-5 h-full flex flex-col justify-between">
                                <div>
                                    <div className="flex flex-col w-full items-center mb-5">
                                        <h3 className="mb-3">hobbyist</h3>
                                        <h1 className="text-5xl">$<strong className="text-5xl">0.00</strong></h1>
                                        <p> /month</p>
                                    </div>
                                    <div className="flex flex-col">
                                        {
                                            FREE_TIER_BENEFITS.map(item => {
                                                return (
                                                    <div className="flex">
                                                        
                                                        <p className="mb-3 flex items-center"> <span><FaCircleCheck className="mr-3" /></span> {item}</p>
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>

                                </div>
                                <a className="font-bold bg-gray-100 hover:bg-gray-400 dark:bg-gray-800  hover:dark:bg-gray-900 p-3 rounded-lg flex justify-center" href="/signup">try for free</a>
                            </div>
                        </div>
                        <div className="rounded-3xl p-3 bg-gradient-to-br dark:from-blue-700 dark:to-cyan-500 from-blue-400 to-cyan-500 p-3">
                            <div className="bg-white/30 dark:bg-black/30 rounded-2xl p-5 h-full flex flex-col justify-between">
                                <div>
                                    <div className="flex flex-col w-full items-center mb-5">
                                        <h3 className="mb-3">professional</h3>
                                        <h1 className="text-5xl">$<strong className="text-5xl">4.99</strong></h1>
                                        <p> /month</p>
                                    </div>
                                    <div className="flex flex-col">
                                        {
                                            PRO_TIER_BENEFITS.map(item => {
                                                return (
                                                    <div className="flex">
                                                        
                                                        <p className="mb-3 flex items-center"> <span><FaCircleCheck className="mr-3" /></span> {item}</p>
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>

                                </div>
                                <a className="font-bold bg-sky-200 hover:bg-cyan-300 dark:bg-sky-500 hover:dark:bg-cyan-500 p-3 rounded-lg flex justify-center" href="/signup">get started</a>

                            </div>
                        </div>

                    </div>
                    
                    
                </div>
                <div className="m-5 md:mx-14 md:p-14 flex flex-col items-end items-center bg-white/50 dark:bg-black/50 backdrop-blur-md">
                    <h1 className="text-7xl font-semibold">arnold</h1>
                    <p className="mb-5">level up your networking game</p>
                    <a target="__blank" className="bg-blue-400 hover:bg-sky-500 text-white rounded-lg p-3 px-7" href="https://chromewebstore.google.com/detail/arnold/oeggnnpjdldnlcblnadgbbojicofmdfh">download from the Chrome Web Store</a>
                </div>

            </div>

        </div>
    //    </ThemeProvider>
    )
}

export default HomeTwo