import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { ThemeProvider } from 'next-themes';
import './index.css';
import App from './App';
import { AuthProvider } from './providers/AuthProvider';



import reportWebVitals from './reportWebVitals';

//pages
// import Home from './pages/Home'
import HomeTwo from './pages/HomeTwo';
import Login from './pages/Login';
import Register from './pages/Register'
import Feedback from './pages/Contact'
import Dashboard from './pages/Dashboard';
//components
import Header from './components/Header'
import Footer from './components/Footer';
import ThankYou from './pages/ThankYou';
import Privacy from './pages/Privacy';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
    {/* <ThemeProvider attribute='class'>
    
      <head>
        <title>Arnold | AI Networking Assistant</title>
        
      </head>
      
      
        <Header />
        <BrowserRouter>
          <Routes>
            <Route index element={<HomeTwo/>} />
            <Route path='/register' element={<Register/>} />
            <Route path='/feedback' element={<Feedback/>} />
            <Route path='/thank-you' element={<ThankYou/>} />
            <Route path='/privacy-policy' element={<Privacy/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/dashboard' element={<Dashboard />} />

            

          </Routes>
        </BrowserRouter>
        <Footer />
      

    </ThemeProvider> */}

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
