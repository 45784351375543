import {useState, useEffect, useContext} from 'react'
import {CgMenuGridO} from 'react-icons/cg'
import useFirebaseUser from '../firebase/useFirebaseUser'
// import { ThemeProvider } from 'next-themes'\
import { AuthContext } from '../providers/AuthProvider'

function Header() {
    const [showMenu, setShowMenu] = useState(false)
    const handleClick = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        document.title = 'Arnold | AI Networking Assistant'
    }, [])

    // const {user} = useFirebaseUser();
    const {user, logout} = useContext(AuthContext)

    


    return(
        // <ThemeProvider attribute='class'>
            <div className='fixed z-10 left-0 right-0 bg-gray-100/50 dark:bg-gray-900/50 backdrop-blur-lg m-5 rounded-lg dark:text-white'>

                <div className="flex justify-between px-6 md:px-12 py-4">
                    <a href="/"><strong>arnold.</strong></a>
                
                    {user ? 
                        <a className='hidden lg:flex font-bold hover:text-gray-500 text-blue-500' href="/dashboard">dashboard</a>
                    :
                        <div className="hidden md:block flex justify-between">
                            <a className="px-3 hover:text-gray-500" href="/#home">home</a>
                            <a className="px-3 hover:text-gray-500" href="/#vision">vision</a>
                            <a className="px-3 hover:text-gray-500" href="/#product">product</a>
                            {/* <a className="px-3 hover:text-gray-500" href="/#people">people</a> */}
                            <a className="px-3 hover:text-gray-500" href="/#pricing">pricing</a>
                        
                        </div>
                    }
                    {user ?
                        <button onClick={logout} className='hidden lg:flex font-bold hover:text-gray-500 text-red-500'>logout</button>
                    :
                    <div className='hidden lg:flex'>
                        <a className='font-bold hover:text-gray-500 text-blue-500 mr-5' href="/signup">sign up </a>
                        <a className='font-bold hover:text-gray-500 text-blue-500' href="/login">login</a>
                    </div>
                    }
                    <button className='block md:hidden' onClick={handleClick}><CgMenuGridO className='text-xl'/></button>
                    
                </div>
                {showMenu ?
                <div className="block md:hidden flex flex-col p-6 pt-0">
                        {user ? 
                            <a className='font-bold hover:text-gray-500 text-blue-500' href="/dashboard">dashboard</a>
                        :
                            <div className='flex flex-col mb-3'>
                                <a className="hover:text-gray-500" href="/#home">home</a>
                                <a className="hover:text-gray-500" href="/#vision">vision</a>
                                <a className="hover:text-gray-500" href="/#product">product</a>
                                {/* <a className="hover:text-gray-500" href="/#people">people</a> */}
                                <a className="hover:text-gray-500" href="/#pricing">pricing</a>
                            
                            </div>
                        }
                        {user ?
                            <button onClick={logout} className='font-bold hover:text-gray-500 text-red-500'>logout</button>
                        :
                            <div className='flex flex-col'>
                                <a className='font-bold hover:text-gray-500 text-blue-500 mr-5' href="/signup">sign up </a>
                                <a className='font-bold hover:text-gray-500 text-blue-500' href="/login">login</a>
                            </div>
                        }
                </div> : null}
            </div>
        // </ThemeProvider>

    )
}

export default Header