const Footer = () => {
    return (
        <div style={{zIndex: 2, position: 'relative'}} className="h-full w-full bg-sky-500 font-semibold text-white flex justify-between p-5">
            <p>arnold. © 2024</p>
            <a href="/privacy-policy">privacy policy</a>
            <a href="/contact">contact us</a>
        </div>
    )
}

export default Footer